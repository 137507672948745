import React from "react";
import Navbar from "./navbar/Navbar";

const Desktop = () => {
	return (
		<div>
			<Navbar />
		</div>
	);
};

export default Desktop;
